import React, { useEffect } from 'react';
import { useStateValue } from '../ContextApi/StateProvider';
import { RUN_CRON_JOB, GET_INSTITUTES, CHANGE_CRON_JOB_STATUS } from '../api/urls';
import useApi from '../api/useApi';

const CronJob = () => {
  const [{ institutes }, dispatch] = useStateValue();

  const [jobRes, runJob] = useApi();
  const [instituteRes, getInstitutes] = useApi();
  const [statusRes, changeJobStatus] = useApi();

  useEffect(() => {
    if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data && instituteRes.data.response) {
      dispatch({
        type: 'INSTITUTES',
        institutes: instituteRes.data.response,
      });
    }
  }, [instituteRes]);

  useEffect(() => {
    if (!jobRes.isLoading && !jobRes.isError && jobRes.status === 200) {
      alert('Success');
      getInstitutes(GET_INSTITUTES, 'GET');
    }

    if (!jobRes.isLoading && jobRes.isError) {
      alert('Error');
    }
  }, [jobRes]);

  const handleRunJobs = () => {
    runJob(RUN_CRON_JOB, 'POST');
  };

  const changeStatus = (id, isActive) => {
    if (window.confirm(`Are you sure you want to ${isActive ? 'Deactivate' : 'Activate'} Cron Job?`))
      changeJobStatus(CHANGE_CRON_JOB_STATUS, 'POST', {
        instituteId: id,
      });
  };

  useEffect(() => {
    if (!statusRes.isLoading && !statusRes.isError && statusRes.status === 200) {
      alert('Success');
      getInstitutes(GET_INSTITUTES, 'GET');
    }

    if (!statusRes.isLoading && statusRes.isError) {
      alert('Error');
    }
  }, [statusRes]);

  return (
    <div className="w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto mb-4">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-1/3">
              <p>Cron Jobs</p>
            </div>
            <div className="flex gap-x-4 w-2/3 justify-end">
              <button
                className="w-32 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                onClick={() => handleRunJobs()}
              >
                Fee Balance
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 px-6">
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/6 border-r-2 py-2 px-2">Name</div>
                  <div className="w-2/6 border-r-2 py-2 px-2">Cron Status</div>
                  <div className="w-2/6 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ maxHeight: '75vh' }}>
                {Array.isArray(institutes) &&
                  institutes.map((x, idx) => {
                    return (
                      <div key={x._id} className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                            institutes.length - 1 !== idx && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-2/6 px-2 text-tableRowText ">{x?.name}</div>
                          <div className="w-2/6 px-2 text-tableRowText ">
                            {x?.cronJobStatus ? 'Active' : 'Not Active'}
                          </div>

                          <div className={`w-2/6 gap-x-3 flex`}>
                            <button
                              className={`px-3 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  ${
                                x?.cronJobStatus
                                  ? 'bg-primary border-primary text-white'
                                  : 'bg-white border-primary text-primary'
                              }`}
                              onClick={() => changeStatus(x._id, x?.cronJobStatus)}
                            >
                              {x?.cronJobStatus ? 'Deactivate' : 'Activate'}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CronJob;
